// Import all of Bootstrap's CSS
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./colors.scss";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

* {
    font-family: 'Lexend', sans-serif;
    color: #FFF;
}

html {
    margin: 0;
    padding: 0;
}

body {
    background: #1e1e1e;
}

.hs-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.hs-button:hover {
    transform: scale(1.1);
    transition: all 1s;
}

.submitted-message {
    color: #FFF;
}
 
.btn:hover {
    transition: all .4s;
    transform: scale(1.1);
}

.btn:disabled {
    background-color: #cbcbcb;
    color: #6f6f6f;
    cursor: not-allowed;
}

.mobile-gif {
    display: none;
    @media (max-width: 490px) {
        display: block;
        margin-bottom: 20px;
    }
}

.sollo-advantages {
    @media (max-width: 490px) {
        margin-top: 70px;
    }
}

.form-success {
    display: none;
}

.container-fluid {
    margin: 0;
    padding: 0;
}

.header {
    background: url('../images/topo_bg_gray.png');
    border-bottom: 1px solid $sol-accent;
    padding-bottom: 30px;
}

.main {
    margin-top: 60px;
    padding-top: 75px;
    padding-bottom: 60px;
}

.nav {
    background: #1e1e1e;
    padding: 20px;
    border-bottom: 1px solid $sol-accent;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 3;
}

.video {
    // width: 450px;
    width: 100%;
    @media (max-width: 490px) {
        display: none;
    }
}

.features {
    .list-group-item {
        background: none;
        padding: 6px 0;
        border: none;
        color: #FFF;
        list-style: circle;
    }
}

.card {
    background: #1e1e1e;
    border: none;
    width: 100%;
}

.btn {
    background: $sol-accent;
    color: #1e1e1e;
    border: none;

    &:hover {
        background-color: #cb7b02;
    }
}

.form-control {
    background: transparent;

    &:focus {
        border-color: $sol-accent;
        box-shadow: none;
    }
}

.form-floating > label {
    background: transparent!important;
}

.vertical {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.light {
    background: #FFF;
}

.clientes {
    padding: 80px 0;

    h4 {
        color: #1e1e1e;
        text-align: center;
    }
    
    .box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
    }

    .round-img {
        img {
            max-width: 40%;
        }
    }

    img {
        max-width: 180px;
    }
}

#how-it-works {
    padding: 60px 0;
    background-color: #1e1e1e;

    .video-use {
        width: 100%;
    }

    .step {
        padding: 28px 15px;
        margin-bottom: 40px;
        border-bottom: 1px solid $sol-accent;
        transition: all .4s;
        &:hover {
            transform: scale(1.1);
            background-color: #3a3a3a;
        }
    }

    h2 {
        color: #FFF;
        text-align: center;
        margin-bottom: 30px;
    }
}

#features {
    background-color: $sol-accent;
    padding: 60px 0;
    
    .bi {
        font-size: 40px;
    }

    .social-holder {
        border-radius: 10px;
        background-color: #3a3a3a;
        text-align: center;
        transition: all .4s;
        &:hover {
            background-color: #4d4d4d;
            color: $sol-accent;
            box-shadow: 0 18px 25px rgba(0,0,0,.4);
            transform: scale(1.1);
        }
    }

    h2 {
        color: #1e1e1e;
        text-align: center;
        margin-bottom: 30px;
    }

    .btn-light {
        margin: 50px auto 0 auto;
        box-shadow: 0 0 10px -1px rgba(0,0,0,.4);
        background-color: #FFF;
        transition: all .4s;
        &:hover {
            transform: scale(1.1);
            background-color: #1e1e1e;
            color: #FFF;
            box-shadow: 0 15px 10px -1px rgba(0,0,0,.4);
        }
    }
}

#mais-beneficios {
    padding: 60px 0;

    h2 {
        color: #1e1e1e;
        margin-bottom: 30px;
        text-align: center;
    }
    .card-style {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #1e1e1e;

        .icon-holder {
            background: #1e1e1e;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            border-radius: 50%;
            font-size: 70px;
            margin-bottom: 20px;
        }

        span {
            color: #1e1e1e;
        }
    }
}

#models {
    padding: 60px 0;
    background: #1e1e1e;

    h2 {
        text-align: center;
        margin-bottom: 30px;
    }

    .card-body {
        text-align: center;
    }

    .sollo-card {
        display: flex;
        flex-direction: column;
        transition: all .4s;
        &:hover {
            background-color: #3a3a3a;
            border-radius: 10px;
            img {
                transition: all .4s;
                transform: scale(1.1);
            }
        }
    }
}

#tradicionais-vs-sollo {
    color: #1e1e1e;
    padding: 60px 0;

    h2 {
        text-align: center;
        color: #1e1e1e;

        b {
            color: #000;
        }
    }

    h4 {
        text-align: center;
        color: #1e1e1e;
    }

    .trad-sollo {
        color: #1e1e1e;
        
        .list-group-item {
            color: #1e1e1e;
            background-color: transparent;
            transition: all .4s;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

#quem-somos {
    padding: 60px 0;
    
    h2 {
        text-align: center;
        margin-bottom: 30px;
    }

    iframe {
        width: 100%;
        height: 300px;
    }

    .list-group-item {
            background-color: transparent;
            transition: all .4s;
            &:hover {
                transform: scale(1.1);
                color: $sol-accent;
            }
        }
}

#na-midia {
    padding: 60px 0;
    text-align: center;
    
    h2 {
        color: #1e1e1e;
        margin-bottom: 30px;
        text-align: center;
    }

    img {
        max-width: 180px;
        transition: all .4s;
        &:hover {
            transform: scale(1.1);
        }
    }
}

#faq {
    padding: 60px 0;

    h2 {
        text-align: center;
        color: #FFF;
        margin-bottom: 30px;
    }

    .accordion-flush {
        h2 {
            margin: 0;
        }
    }

    .accordion-button {
        background-color: transparent;
    }

    .accordion-button:not(.collapsed) {
        background-color: $sol-accent;
        color: #FFF;
    }

    .accordion-button:after {
        filter: hue-rotate(222deg);
    }
}

#vendas {
    padding: 60px 0;
    color: #1e1e1e;
    text-align: center;

    h2 {
        color: #1e1e1e;
    }

    span {
        color: #1e1e1e;
    }
}

footer {
    padding: 60px 0;

    .address {
        margin-top: 20px;
        text-align: center;
    }

    address {
        font-size: 13px;
        text-align: center;
    }

    .note {
        font-size: 12px;
        text-align: center;
        margin-top: 20px;
    }
}
